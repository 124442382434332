<template>
    <div class="customer-info-module module checkout-module" id="checkout_step_1">

        <template v-if="$store.state.checkout.step_1_status === 'complete'">

            <div class="module-heading" id="customer-info-heading">
                <h2 class="h4">1) Customer Info<span class="d-none d-sm-inline">rmation</span></h2>
                <p>
                    <a class="vue-anchor text-purple-medium" href="javascript:void(0)" @click="editClick()">Edit</a>
                    <span class="icon-check"></span>
                </p>
            </div>

            <div class="module-completed-properties module-inner-content">
                <div class="row">
                    <div class="col-md-6">
                        <h3 class="h5 text-purple">Contact Information</h3>
                    </div>
                    <div class="col-md-6">
                        <p class="mb-0">{{$store.state.checkout.user.name}}</p>
                        <p class="mb-0">{{$store.state.checkout.user.phone}}</p>
                        <p class="mb-0">{{$store.state.checkout.user.email}}</p>
                    </div>
                </div>
                <hr class="my-2">
                <div class="row">
                    <div class="col-md-6">
                        <h3 class="h5 text-purple">Shipping Address</h3>
                    </div>
                    <div class="col-md-6">
                        <p>{{$store.state.checkout.user.shipping_address.shipping_first_name}} {{$store.state.checkout.user.shipping_address.shipping_last_name}} <br>
                        {{$store.state.checkout.user.shipping_address.shipping_address_1}} {{$store.state.checkout.user.shipping_address.shipping_address_2}} <br>
                        {{$store.state.checkout.user.shipping_address.shipping_city}}, {{$store.state.checkout.user.shipping_address.shipping_state}} {{$store.state.checkout.user.shipping_address.shipping_postcode}}</p>
                    </div>
                </div>
            </div>

        </template>

        <template v-else>

            <div class="module-heading" id="customer-info-heading">
                <h2 class="h4">1) Customer Info<span class="d-none d-sm-inline">rmation</span></h2>
                <p class="h5">Step 1 of 3</p>
            </div>

            <transition name="slide">
                <div class="module-inner-content">
                    <template v-if="errors.length != 0">
                        <p class="help-block">There are some errors / required fields you may have missed below</p>
                    </template>
                    <form v-on:submit.prevent="submit">

                        <h3 class="h5 text-purple">Contact Information</h3>

                        <div class="form-row">
                            <div class="form-group col-md-6" :class="errors.name ? ' has-error' : ''">
                                <label for="name">Full Name</label>
                                <input type="text" class="form-control" id="name" placeholder="Enter Full Name" v-model="$store.state.checkout.user.name">
                                <form-error v-if="errors.name" :errors="errors">
                                    {{ errors.name[0] }}
                                </form-error>
                            </div>
                            <div class="form-group col-md-6" :class="errors.phone ? ' has-error' : ''">
                                <label for="phone">Phone</label>
                                <input type="text" class="form-control" id="phone" placeholder="Enter Phone Number" v-model="$store.state.checkout.user.phone"
                                       :required="$store.state.checkout.user.create_account">
                                <form-error v-if="errors.phone" :errors="errors">
                                    {{ errors.phone[0] }}
                                </form-error>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6" :class="errors.email ? ' has-error' : ''">
                                <label for="email">Email</label>
                                <input type="email" class="form-control" id="email" placeholder="Enter Email" v-model="$store.state.checkout.user.email" :disabled="$parent.authcheck == true" required="true">
                                <form-error v-if="errors.email" :errors="errors">
                                    {{ errors.email[0] }}
                                </form-error>
                            </div>
                            <div class="form-group col-md-6" v-if="!$parent.authcheck" :class="errors.password ? ' has-error' : ''">
                                <label for="password">Password</label>
                                <span class="tooltip-info" id="tooltip-target-1">?</span>
                                <b-tooltip target="tooltip-target-1" triggers="hover" variant="light"> <!-- danger, warning, success, primary, secondary, info, light, and dark. -->
                                   <em class="password-tooltip">Password is necessary for account creation. Account creation not necessary for purchase. Account can be created during test activation. </em>
                                </b-tooltip>

                                <input type="password" class="form-control" autocomplete="off" id="password" placeholder="Enter Password" aria-describedby="passwordHelpBlock" v-model="password" :required="$store.state.checkout.user.create_account == true">
                                <span id="passwordHelpBlock" class="form-text text-small">Your password should be at least six characters long.</span>
                                <form-error v-if="errors.password" :errors="errors">
                                    {{ errors.password[0] }}
                                </form-error>
                            </div>
                        </div>

                        <template v-if="!$parent.authcheck">
                            <hr>
                            <div class="form-row align-items-start">
                                <div class="form-group col-lg-6 my-1">
                                    <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" id="create_account" value="create_account" v-model="$store.state.checkout.user.create_account">
                                        <label for="create_account" class="custom-control-label">
                                            <span class="d-block">Create My Account Now</span>
                                            <small class="text-gray text-italic">Saves you time during test activation​</small>
                                        </label>
                                    </div>
                                </div>

                                <!-- <div class="form-group col-lg-6 my-1">
                                    <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" id="exclusive_offers" value="exclusive_offers" v-model="$store.state.checkout.user.exclusive_offers" checked>
                                        <label for="exclusive_offers" class="custom-control-label">
                                            <span class="d-block">Sign Up For Exclusive Offers</span>
                                            <small class="text-gray text-italic">I agree to receive product updates & exclusive offers</small>
                                        </label>
                                    </div>
                                </div> -->

                            </div>
                            <hr>
                        </template>

                        <h3 class="h5 text-purple mt-3">Shipping Address</h3>

                        <div class="form-row">
                            <div class="form-group col-md-6" :class="errors.shipping_first_name ? ' has-error' : ''">
                                <label for="shipping_first_name">First Name</label>
                                <input type="text" class="form-control" id="shipping_first_name" placeholder="Enter First Name" v-model="$store.state.checkout.user.shipping_address.shipping_first_name">
                                <form-error v-if="errors.shipping_first_name" :errors="errors">
                                    {{ errors.shipping_first_name[0] }}
                                </form-error>
                            </div>
                            <div class="form-group col-md-6" :class="errors.shipping_last_name ? ' has-error' : ''">
                                <label for="shipping_last_name">Last Name</label>
                                <input type="text" class="form-control" id="shipping_last_name" placeholder="Enter Last Name" v-model="$store.state.checkout.user.shipping_address.shipping_last_name">
                                <form-error v-if="errors.shipping_last_name" :errors="errors">
                                    {{ errors.shipping_last_name[0] }}
                                </form-error>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="shipping_company">Company (Optional)</label>
                                <input type="text" class="form-control" id="shipping_company" placeholder="Enter Company Name (Optional)" v-model="$store.state.checkout.user.shipping_address.shipping_company">
                              <form-error v-if="errors.shipping_company" :errors="errors">
                                {{ errors.shipping_company[0] }}
                              </form-error>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12" :class="errors.shipping_address_1 ? ' has-error' : ''">
                                <label for="shipping_address_1">Address</label>
                                <input type="text" class="form-control" id="shipping_address_1" placeholder="Enter Address" v-model="$store.state.checkout.user.shipping_address.shipping_address_1">
                                <form-error v-if="errors.shipping_address_1" :errors="errors">
                                    {{ errors.shipping_address_1[0] }}
                                </form-error>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="shipping_address_2">Apartment, Suite, Building (Optional)</label>
                                <input type="text" class="form-control" id="shipping_address_2" placeholder="Enter Address Line 2 (Optional)" v-model="$store.state.checkout.user.shipping_address.shipping_address_2">
                                <form-error v-if="errors.shipping_address_2" :errors="errors">
                                    {{ errors.shipping_address_2[0] }}
                                </form-error>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6" :class="errors.shipping_city ? ' has-error' : ''">
                                <label for="shipping_city">City</label>
                                <input type="text" class="form-control" id="shipping_city" placeholder="Enter City" v-model="$store.state.checkout.user.shipping_address.shipping_city">
                                <form-error v-if="errors.shipping_city" :errors="errors">
                                    {{ errors.shipping_city[0] }}
                                </form-error>
                            </div>
                            <div class="form-group col-md-6" :class="errors.shipping_state ? ' has-error' : ''">
                                <label for="shipping_state">State</label>
                                <!-- <input type="text" class="form-control" id="shipping_state" placeholder="Enter State" v-model="$store.state.checkout.user.shipping_address.shipping_state"> -->

                                <select  class="form-control" id="shipping_state" v-model="$store.state.checkout.user.shipping_address.shipping_state">
                                    <option value="" disabled="" selected="">Enter State</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">District Of Columbia</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                </select>

                                <form-error v-if="errors.shipping_state" :errors="errors">
                                    {{ errors.shipping_state[0] }}
                                </form-error>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6" :class="errors.shipping_postcode ? ' has-error' : ''">
                                <label for="shipping_postcode">Zip Code</label>
                                <input type="text" class="form-control" id="shipping_postcode" placeholder="Enter Zip Code" v-model="$store.state.checkout.user.shipping_address.shipping_postcode">
                                <form-error v-if="errors.shipping_postcode" :errors="errors">
                                    {{ errors.shipping_postcode[0] }}
                                </form-error>
                            </div>
                            <div class="form-group col-md-6" :class="errors.shipping_country ? ' has-error' : ''">
                                <label for="shipping_country">Country</label>
                                <select class="form-control" id="shipping_country" v-model="$store.state.checkout.user.shipping_address.shipping_country" >
                                    <option value="US" selected>United States</option>
                                </select>
                                <form-error v-if="errors.shipping_country" :errors="errors">
                                    {{ errors.shipping_country[0] }}
                                </form-error>
                            </div>
                        </div>
                         <div class="form-row" v-if="errors.tax">
                                <form-error v-if="errors.tax" :errors="errors">
                                   Tax Calculation Error: {{ errors.tax[0] }}
                                </form-error>
                         </div>
                        <h3 class="h5 text-purple mt-3">Shipping Method</h3>

                        <div class="row" v-if="hasShippingMethods">
                            <div class="col-md-12">
                                 <table class="shipping-methods-table table text-gray">
                                  <tbody>
                                    <tr v-for="method in shippingMethods" v-bind:class="{ 'has-error': errors.shipping_method_id, 'active': method.id == $store.state.checkout.shipping.shipping_method_id }">
                                        <td>

                                            <div class="custom-control custom-radio">
                                                <input class="custom-control-input" type="radio" :id="'shipping_method_'+method.id" name="shipping_method" :value="method.id" v-model="$store.state.checkout.shipping.shipping_method_id" v-on:change="updateSelectedShippingState(method.title,method.settings.cost.value)">
                                                <label class="custom-control-label" :for="'shipping_method_'+method.id">
                                                    <span>{{method.title}}</span>
                                                </label>
                                            </div>
                                        </td>
                                        <td>
                                            <template v-if="method.title === 'USPS First-Class Mail®'">
                                                <p class="shipping_description text-small mb-0">1-5 Business Days</p>
                                            </template>
                                            <template v-if="method.title === 'USPS Priority Mail®'">
                                                <p class="shipping_description text-small mb-0">1-3 Business Days</p>
                                            </template>
                                            <template v-if="method.title === 'USPS Priority Mail Express®'">
                                                <p class="shipping_description text-small mb-0">1-2 Business Days</p>
                                            </template>
                                        </td>
                                        <td>
                                            <div class="shipping_price text-sm-right" v-if="method.settings.cost.value != 0">{{ formatCurrency(method.settings.cost.value)  }} each
                                                <!-- qty: {{$store.state.checkout.count}} Total: {{ method.settings.cost.value * $store.state.checkout.count | formatCurrency}} -->
                                            </div>
                                            <div class="shipping_price text-sm-right" v-else>Free</div>
                                        </td >
                                    </tr>

                                  </tbody>
                                </table>
                                <form-error v-if="errors.shipping_method_id" :errors="errors">
                                    {{ errors.shipping_method_id[0] }}
                                </form-error>
                                <!-- {{$store.state.checkout.shipping.shipping_method}} -->
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-12">
                                <button type="submit" class="btn btn-primary mb-0">Continue</button>
                            </div>
                        </div>

                    </form>
                </div>
            </transition>
            </template>

        </div>
    </template>

    <script>
    // import FormError from './modules/FormErros.vue';

        export default {
            mounted() {
                this.$store.state.checkout.user.shipping_address.shipping_country = 'US';
                this.getShippingMethods();
            },
            data: function () {
                return {
                    password:null,
                    hasShippingMethods:false,
                    shippingMethods:null,
                    errors:[]
                }
            },
            // components: {
            //     FormError,
            // },
            methods:{
                formatCurrency(value) {
                    return this.$filters.formatCurrency(value);
                },
                editClick(){
                    this.$store.state.checkout.active_step = 1;
                    this.$store.state.checkout.step_1_status = 'active';
                    this.$store.state.checkout.step_2_status = 'pending';
                    this.$store.state.checkout.step_3_status = 'pending';
                    this.$store.state.checkout.disablecoupons=true;

                },
                getShippingMethods(){
                    axios.get('/api/v1/commerce/shipping-methods')
                    .then(success => {
                        //this.$root.consolelog(success.data);
                        //console.log(success.data);

                        if (success.data.success) {
                            //this.shippingMethods = success.data.data;

                            this.shippingMethods = success.data.data.filter(value => {
                                // console.log(value)
                                if (value.enabled == true) {
                                    // console.log('true')
                                    return value;
                                }
                            })

                            this.hasShippingMethods = true;
                        }else{
                            //this.$root.consolelog('trouble getting shipping methods')
                            console.log('trouble getting shipping methods')
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                },
                updateSelectedShippingState(title, cost){
                    this.$store.state.checkout.shipping.shipping_title = title;
                    this.$store.state.checkout.shipping.shipping_cost = cost * this.$store.state.checkout.count;

                    let shipping_cost = typeof this.$store.state.checkout.shipping.shipping_cost === 'string' ? this.$store.state.checkout.shipping.shipping_cost.replace(',','') : this.$store.state.checkout.shipping.shipping_cost;
                    shipping_cost = +shipping_cost;

                    let subtotal = typeof this.$store.state.checkout.subtotal === 'string' ? this.$store.state.checkout.subtotal.replace(',','') : this.$store.state.checkout.subtotal;
                    subtotal = +subtotal;

                    let tax = typeof this.$store.state.checkout.tax.amount_to_collect === 'string' ? this.$store.state.checkout.tax.amount_to_collect.replace(',','') : this.$store.state.checkout.tax.amount_to_collect;
                    tax = +tax;

                    const newTotal = (shipping_cost + subtotal + tax).toFixed(2);
                    this.$store.state.checkout.total = newTotal;
                },
                submit(){
                    //this.$root.consolelog('submitted');
                    //console.log('submitted');
                    //make axios call to update session and validate this data
                    this.$store.commit('setLoading' , true);

                    if(!!this.$store.state.checkout.user.phone){
                        this.$store.state.checkout.user.phone = this.$store.state.checkout.user.phone.replace(/[*&\^!%#$.()-\s]|\+\D*1/g, "");
                    }

                    axios.post('/checkout/customer-info', //this is the storeapi .php route file on the marketing site
                    {
                        name:this.$store.state.checkout.user.name,
                        phone: !!this.$store.state.checkout.user.phone ? this.$store.state.checkout.user.phone.replace(/\D/g,'') : this.$store.state.checkout.user.phone,
                        email: this.$store.state.checkout.user.email,
                        password:this.password,
                        create_account:this.$store.state.checkout.user.create_account,
                        exclusive_offers:this.$store.state.checkout.user.exclusive_offers,
                        shipping_first_name:this.$store.state.checkout.user.shipping_address.shipping_first_name,
                        shipping_last_name:this.$store.state.checkout.user.shipping_address.shipping_last_name,
                        shipping_company:this.$store.state.checkout.user.shipping_address.shipping_company,
                        shipping_address_1:this.$store.state.checkout.user.shipping_address.shipping_address_1,
                        shipping_address_2:this.$store.state.checkout.user.shipping_address.shipping_address_2,
                        shipping_city:this.$store.state.checkout.user.shipping_address.shipping_city,
                        shipping_state:this.$store.state.checkout.user.shipping_address.shipping_state,
                        shipping_postcode:this.$store.state.checkout.user.shipping_address.shipping_postcode,
                        shipping_country:this.$store.state.checkout.user.shipping_address.shipping_country,
                        shipping_method_id:this.$store.state.checkout.shipping.shipping_method_id,
                        shipping_title:this.$store.state.checkout.shipping.shipping_title,
                        shipping_cost:this.$store.state.checkout.shipping.shipping_cost,
                    },
                    {
                        headers: {
                            //'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(success => {
                        //console.log(success.data);

                        if (success.data.success) {
                            //console.log('success')
                            this.$store.state.checkout.step_1_status = 'complete';
                            this.$store.state.checkout.step_2_status = 'active';
                            this.$store.state.checkout.active_step = 2;

                            //set default billing address to shipping address
                            if (!this.$store.state.checkout.payment.billing_address.billing_first_name) {
                                this.$store.state.checkout.payment.billing_address.billing_first_name = this.$store.state.checkout.user.shipping_address.shipping_first_name;
                                this.$store.state.checkout.payment.billing_address.billing_last_name = this.$store.state.checkout.user.shipping_address.shipping_last_name;
                                this.$store.state.checkout.payment.billing_address.billing_company = this.$store.state.checkout.user.shipping_address.shipping_company;
                                this.$store.state.checkout.payment.billing_address.billing_address_1 = this.$store.state.checkout.user.shipping_address.shipping_address_1;
                                this.$store.state.checkout.payment.billing_address.billing_address_2 = this.$store.state.checkout.user.shipping_address.shipping_address_2;
                                this.$store.state.checkout.payment.billing_address.billing_city = this.$store.state.checkout.user.shipping_address.shipping_city;
                                this.$store.state.checkout.payment.billing_address.billing_state = this.$store.state.checkout.user.shipping_address.shipping_state;
                                this.$store.state.checkout.payment.billing_address.billing_postcode = this.$store.state.checkout.user.shipping_address.shipping_postcode;
                                this.$store.state.checkout.payment.billing_address.billing_country = this.$store.state.checkout.user.shipping_address.shipping_country;
                            }

                            this.$store.state.checkout.tax.amount_to_collect = success.data.data.tax.data.amount_to_collect;
                            this.$root.updateCartTotals();

                            //GTM
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                              event: 'eec.checkout',
                              ecommerce: {
                                checkout: {
                                  actionField: {
                                    step: 1 + ' Customer'
                                  },
                                  products:this.$store.state.checkout.items
                                }
                              }
                            });

                            // this.$nextTick(function () {
                            //     console.log('next tick jump checkout_step_2')
                            //     this.$root.jump('checkout_step_2');
                            // })


                        }else{
                          this.errors = success.data.data;
                          this.$root.jump('customer-info-heading')
                        }
                        // if (success.data.success) {
                        //     this.$root.$bvToast.toast(`Added ${this.pet.name} `, {
                        //         title: 'Success',
                        //         variant:'success',
                        //         autoHideDelay: 5000,
                        //         appendToast: true
                        //     })
                        //     this.$store.commit('setLoading' , false);

                        //     if (this.prev_page == undefined ) {
                        //         this.$router.push({ name: this.prev_page})
                        //     }else{
                        //         this.$router.push({ name: 'MyPets'})
                        //     }

                        //     //this.$router.push({ name: 'MyPets'}) //catch a page reload
                        // }else{
                        //     this.$store.commit('setLoading' , false);
                        //     this.errors = success.data.data
                        // }
                        //
                        this.$store.commit('setLoading' , false);
                        // this.$root.jump('customer-billing-info-heading');
                    })
                    .catch(function (error) {
                        console.log(error);
                    });


            }
        }
    }
</script>
<style type="text/css">
.slide-enter-active {
 -moz-transition-duration: 0.3s;
 -webkit-transition-duration: 0.3s;
 -o-transition-duration: 0.3s;
 transition-duration: 0.3s;
 -moz-transition-timing-function: ease-in;
 -webkit-transition-timing-function: ease-in;
 -o-transition-timing-function: ease-in;
 transition-timing-function: ease-in;
}

.slide-leave-active {
 -moz-transition-duration: 0.3s;
 -webkit-transition-duration: 0.3s;
 -o-transition-duration: 0.3s;
 transition-duration: 0.3s;
 -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
 -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
 -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
 transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
 max-height: 100px;
 overflow: hidden;
}

.slide-enter, .slide-leave-to {
 overflow: hidden;
 max-height: 0;
}

</style>
