import VuexPersistence from 'vuex-persist'
import mitt from 'mitt'
import { BootstrapVue } from 'bootstrap-vue'
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createStore } from 'vuex'
import 'core-js/stable'

// Import dependencies
require('./bootstrap')
require('./altos/scripts_store')
require('./vendor/picturefill/picturefill.min.js')

// Create event bus
const eventBus = mitt()

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
})

// Create Vuex store
const store = createStore({
    state: {
        loading: false,
        checkout: {
            disablecoupons: false,
            errors: null,
            step_1_status: 'active',
            step_2_status: 'pending',
            step_3_status: 'pending',
            use_saved_card: false,
            active_step: 1,
            authcheck: null,
            total: null,
            initTotal: null,
            subtotal: null,
            i_agree: false,
            payment_intent_id: null,
            user: {
                name: null,
                phone: null,
                email: null,
                create_account: null,
                exclusive_offers: true,
                shipping_address: {
                    shipping_first_name: null,
                    shipping_last_name: null,
                    shipping_company: null,
                    shipping_address_1: null,
                    shipping_address_2: null,
                    shipping_city: null,
                    shipping_state: null,
                    shipping_postcode: null,
                    shipping_country: null,
                },
                has_payment_method: null,
            },
            payment_method: {
                id: null,
                customer_id: null,
                card: {
                    brand: null,
                    exp_month: null,
                    exp_year: null,
                    last4: null,
                },
                address: {
                    city: null,
                    country: null,
                    line1: null,
                    line2: null,
                    postal_code: null,
                    state: null,
                },
            },
            payment: {
                credit_card: null,
                name_on_card: null,
                exp_month: null,
                exp_year: null,
                ccv: null,
                save_payment_info: null,
                billing_address: {
                    billing_first_name: null,
                    billing_last_name: null,
                    billing_company: null,
                    billing_address_1: null,
                    billing_address_2: null,
                    billing_city: null,
                    billing_state: null,
                    billing_postcode: null,
                    billing_country: null,
                },
            },
            shipping: {
                shipping_method_id: null,
                shipping_title: null,
                shipping_cost: null,
            },
            tax: {
                amount_to_collect: null,
            },
            coupons: [],
            items: {},
            count: null,
        },
    },
    mutations: {
        setLoading(state, n) {
            state.loading = n
        },
        setTotal(state, n) {
            state.checkout.total = n
        },
        setSubTotal(state, n) {
            state.checkout.subtotal = n
        },
        setInitTotal(state, n) {
            console.log('set init total.')
            state.checkout.initTotal = n
        },
        setItems(state, n) {
            state.checkout.items = n
        },
        setUserShippingAddress(state, value) {
            state.checkout.user.shipping_address = value
        },
        setUserShippingMethod(state, value) {
            state.checkout.shipping_method = value
        },
        setUser(state, n) {
            state.checkout.user = n
        },
        setCoupons(state, n) {
            state.checkout.coupons = n
        },
    },
    getters: {
        getLoadingState: (state) => state.loading,
        getItems: (state) => state.checkout.items,
        getCheckoutUser: (state) => state.checkout.user,
    },
    plugins: [
        vuexLocal.plugin,
    ],
})

// Create Vue router
const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/cart', component: () => import('./components/cart/CartComponent.vue') },
        { path: '/checkout', component: () => import('./components/cart/CheckoutComponent.vue') },
    ],
})

// Create Vue app
const app = createApp({
    setup() {
        return {}
    },
    mounted() {
        // Example of accessing the store and methods on mount
        this.getCount()
    },
    methods: {
        getCount() {
            axios
                .get('/' + window.cart_url + '/count')
                .then((success) => {
                    console.log(success.data)
                    if (success.data.data) {
                        this.$store.commit('setItems', success.data.data)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        updateCartTotals() {
            axios
                .get('checkout/cart-totals')
                .then((success) => {
                    if (success.data.success) {
                        this.$store.commit('setTotal', success.data.data.total)
                        this.$store.commit('setSubTotal', success.data.data.subtotal)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        getCoupons() {
            axios
                .get('checkout/cart-coupons')
                .then((success) => {
                    if (success.data.success) {
                        this.$store.commit('setCoupons', success.data.data)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        isDesktop(){
            if ( this.$mq === 'lg' || this.$mq === 'xl' || this.$mq === 'xxl' ) {
                return true;
            } else {
                return false;
            }
        },
    },
})

// Use event bus
app.config.globalProperties.$bus = eventBus

// Use store and router
app.use(store)
app.use(router)
app.use(BootstrapVue)

// Global component registration
app.component('cart-count', require('./components/cart/CartCountComponent.vue').default)
app.component('add-to-cart', require('./components/cart/AddToCartComponent.vue').default)
app.component('cart', require('./components/cart/CartComponent.vue').default)
app.component('checkout', require('./components/cart/CheckoutComponent.vue').default)
app.component('form-error', require('./components/cart/modules/FormErrors.vue').default)


// Global filters as methods
app.config.globalProperties.$filters = {
    formatCurrency(value) {
        const formattedValue = typeof value === 'string' ? value.replace(',', '') : value
        const dollarUSLocale = Intl.NumberFormat('en-US')
        return '$' + dollarUSLocale.format(parseFloat(formattedValue))
    },
    capitalize(s) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    },
}

// Mount the app
app.mount('#app')
