var jwt_token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hZG1pbi5jbHVlamF5LnRlc3QiLCJpYXQiOjE1NzAxMjA3ODIsIm5iZiI6MTU3MDEyMDc4MiwiZXhwIjoxNTcwNzI1NTgyLCJkYXRhIjp7InVzZXIiOnsiaWQiOiIyIn19fQ.Kp1K6uc4F1Xk2i7QsTtmPbAKh1SXHZ0PRC7rDWSORDk';
//var querystring = require('querystring');

//if the page has a contact form
if($('.wpcf7-form').length){
    var form = $('.wpcf7-form');
    form.submit(function($form_id) {
        event.preventDefault();
        var form_action = form.attr('action');
        var form_id = get_form_id(form_action);
        var values = {};
        $.each($('.wpcf7-form').serializeArray(), function(i, field) {
            values[field.name] = field.value;
        });
        // console.log(form_id);
        // console.log(values);
        post_wp_form(form_id, values);
    });
}

function get_form_id(form_action){
    return $('input[name ="_wpcf7"]').val();
}

function post_wp_form(form_id, body){
    //https://github.com/axios/axios/issues/350#issuecomment-227270046   issues with axios sending application/x-www-form-urlencoded
   /* axios.post(WORDPRESS_WEBSITE+'/wp-json/contact-form-7/v1/contact-forms/'+ form_id +'/feedback',querystring.stringify(body),
           {
            headers: {
                Authorization: 'Bearer ' + jwt_token,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        ).then((response) => {
            var response_div = $('.wpcf7-response-output');
            response_div.html(``)


            if (response.data.status == 'validation_failed') {
                var invalid_fields = response.data.invalidFields;
                return_form_errors(invalid_fields);

                response_div.removeClass('wpcf7-display-none');
                response_div.html(`<p>${response.data.message}</p>`)
            }

            if (response.data.status == 'mail_sent') {
                form.slideUp();
                form.after(`<div class="success-message"><p>${response.data.message}</p></div>`)
            }

            console.log(response)
        }).catch((error) => {
          console.log(error)
      });*/

    }

    function return_form_errors(invalid_fields){
        console.log(invalid_fields);
        $('.error').remove(); //remove all prev errors
        _.forEach(invalid_fields, function(field) {
        // $(`'${field.into}'`).after('<div>' + field.message + '</div>')
        $(`${field.into}`).addClass('error').after('<div class="error-message">' + field.message + '</div>')
    });

    }



