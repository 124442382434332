<template>
    <div class="cart">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-sm-6">
                    <h1 class="mb-1">Your Cart</h1>
                </div>
                <div class="col-12 col-sm-6 d-none d-sm-block">
                    <div class="cart-buttons" v-if="items.length > 0">
                        <a :href="store_url" class="btn btn-link d-none d-md-block">Continue Shopping</a>
                        <a :href="checkout_url" class="btn btn-primary">Checkout</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <template v-if="items.length > 0">

                        <table class="cart-items">
                            <thead class="d-none">
                                <tr>
                                    <th class="first"></th>
                                    <th>Description</th>
                                    <th class="last">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="cart-item-row" v-for="(item, index) in items">

                                    <td class="thumbnail d-none d-sm-block">
                                        <div class="cart-item-thumbnail" v-if="item.attributes.images.length > 0">
                                            <img :src="item.attributes.images[0].src" :alt="item.attributes.images[0].alt">
                                        </div>
                                        <div v-else>
                                            <img src="storage/images/icon.png" alt="Cluejay Product Logo">
                                        </div>
                                    </td>
                                    <td class="details">
                                        <div>
                                            <div class="cart-item-description">
                                                <p class="h4 mb-0 mb-lg-1">{{item.name}}</p>
                                                <p class="text-small d-none d-lg-block"><a href="#" v-on:click="removeItem(item.id, $event, item.name, item.quantity)" class="pulled-element remove-from-cart">Remove</a></p>
                                            </div>

                                            <div class="cart-item-price mb-1 mb-lg-0">
                                                <span class="font-weight-bold">{{ formatCurrency(item.price) }}</span>
                                            </div>

                                            <div class="cart-item-qty qty-field d-flex align-items-center">
                                                <!-- <input type="number" name="qty-item1" @keyup.zero="keyupToPreventInputZero" @keyup.enter="enterMethod($event.target)" v-model="item.quantity" min="1" max="999" class="qty-input" @blur="updateItemQty(item.id,item.quantity)"> -->

                                                <div class="input-group">
                                                    <label class="ml-0" for="quantity screen-reader-text">Qty:</label>
                                                    <button type="button" data-type="minus" data-field="quantity" class="input-group-btn d-none d-lg-block" @click="updateItemQtyButton(item.id,item.quantity,index,'-')">
                                                        <span class="icon-minus"></span>
                                                    </button>
                                                    <input class="input-number text-gray mb-0" type="text" name="qty-item1" inputmode="numeric" min="1" max="100" step="1" aria-label="quantity" v-model="item.quantity" @keyup.zero="keyupToPreventInputZero" @keyup.enter="enterMethod($event.target)" @blur="updateItemQty(item.id,item.quantity)">
                                                    <button type="button" data-type="plus" data-field="quantity" class="input-group-btn d-none d-lg-block" @click="updateItemQtyButton(item.id,item.quantity,index,'+')">
                                                        <span class="icon-plus"></span>
                                                    </button>
                                                </div>

                                                <p class="text-small d-block d-lg-none ml-1 mb-0"><a href="javascript:void(0)" v-on:click="removeItem(item.id, $event)" class="pulled-element remove-from-cart">Remove</a></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="total">{{formatCurrency(individualItemPriceAdjustment(item.price, item.quantity)) }}</td>
                                </tr>
                            </tbody>
                        </table>

                    </template>

                    <template v-else>

                        <div class="emptyCart text-center mt-3 mb-6">
                            <p class="h3 text-gray">Your cart is empty.</p>
                        </div>

                    </template>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-md-5">
                    <h2 class="h4">Shipping Info</h2>
                    <p class="text-small">Orders ship within one business day, Monday – Friday.<br>
                     Shipping options: USPS First Class (FREE), Priority, Priority Express<br>
                    Need help? <a href="/contact" target="blank">Get Help</a></p>
                </div>
                <div class="col-md-4 col-lg-3 offset-lg-1">
                    <h2 class="h4">Accepted Payments</h2>
                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <img src="storage/images/cart/visa.svg" alt="Visa">
                        </li>
                        <li class="list-inline-item">
                            <img src="storage/images/cart/mastercard.svg" alt="Master Card">
                        </li>
                        <li class="list-inline-item">
                            <img src="storage/images/cart/american-express.svg" alt="American Express">
                        </li>
                        <li class="list-inline-item">
                            <img src="storage/images/cart/discover.svg" alt="Discover">
                        </li>
                        <li class="list-inline-item">
                            <img src="storage/images/cart/dinerclubs.svg" alt="Diners Club">
                        </li>
                    </ul>
                </div>
                <div class="col-md-3">
                    <div class="d-flex justify-content-end align-items-center mb-2">
                        <p class="qty text-right mr-1 mb-0">Total</p>
                        <p class="total h2 text-gray mb-0">${{details.total}}</p>
                    </div>

                    <div class="text-right" v-if="items.length > 0">
                        <a :href="checkout_url" class="btn btn-primary ">Checkout</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['store_url','checkout_url'],
        data: function () {
            return {
                details: {
                    sub_total: 0,
                    total: 0,
                    total_quantity: 0
                },
                items: [],
                item: {
                    id: '',
                    name: '',
                    price: 0.00,
                    qty: 1
                },
                cartCondition: {
                    name: '',
                    type: '',
                    target: '',
                    value: '',
                    attributes: {
                        description: 'Value Added Tax'
                    }
                },
                options: {
                    target: [
                    {
                        label: 'Apply to SubTotal',
                        key: 'subtotal'
                    },
                    {
                        label: 'Apply to Total',
                        key: 'total'
                    }
                    ]
                },
                currentItemQty:''
            } //end data return
        }, //end data
        mounted:function(){
            this.loadItems();
        },
        computed:{},
        methods: {
            formatCurrency(value) {
                return this.$filters.formatCurrency(value);
            },
            loadItems: function() {
                var _this = this;
                axios.get('/cart/fetch', {
                    params: {
                        limit:10
                    }
                })
                .then(success => {
                    //console.log(success.data);
                    _this.items = success.data.data;
                    _this.loadCartDetails();

                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            loadCartDetails: function() {
                var _this = this;
                axios.get('/cart/details', {
                })
                .then(success => {
                    //console.log(success.data);
                    _this.details = success.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            addItem: function(e) {
                e.preventDefault();
                var _this = this;
                axios.post('/cart', {
                    _token:_this._token,
                    id:_this.item.id,
                    name:_this.item.name,
                    price:_this.item.price,
                    qty:_this.item.qty
                })
                .then(success => {
                    //console.log('additem');
                    //console.log(success.data)
                    _this.loadItems();
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            addCartCondition: function() {
                var _this = this;
                axios.post('/cart/conditions', {

                    _token:_this._token,
                    name:_this.cartCondition.name,
                    type:_this.cartCondition.type,
                    target:_this.cartCondition.target,
                    value:_this.cartCondition.value,
                })
                .then(success => {
                    _this.loadItems();
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            clearCartCondition: function() {
                var _this = this;
                axios.delete('/cart/conditions?_token=' + _token, {
                })
                .then(success => {
                    _this.loadItems();
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            removeItem: function(id, e = null, name, quantity) {
                // e.preventDefault();

                var _this = this;

                axios.delete('/cart/remove', {
                    params: {
                        _token:_this._token,
                        id:id
                    }
                })
                .then(success => {
                    _this.loadItems();

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: 'eec.remove',
                      ecommerce: {
                        remove: {
                            actionField: {
                                list: 'Cart'
                            },
                            product: {
                                id:id,
                                name:name,
                                quantity:quantity
                            }
                        }
                      }
                    });

                    //var data = id;
                    // this.eventHub.$emit('updateCartQty', data)
                    // flash('Item Removed From Cart');
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            //this is triggered in input blur for the qty fields
            updateItemQty: function(sku, value){
                var _this = this;
                if (value == 0) {
                    var _this = this;
                    _this.removeItem(sku);
                    // axios.delete('/cart/'+sku, {
                    //     params: {
                    //         _token:_this._token
                    //     }
                    // })
                    // .then(success => {
                    //     _this.loadItems();
                    //     var data = sku;
                    //     this.eventHub.$emit('updateCartQty', data)
                    //     flash('Item Removed From Cart');
                    // })
                    // .catch(function (error) {
                    //     console.log(error);
                    // });
                }else{
                    //update the cart
                    axios.post('/cart/updateItemQty', {
                        _token:_this._token,
                        id: sku,
                        qty: value
                    })
                    .then(success => {
                        var data = sku;
                        // this.eventHub.$emit('updateCartQty', data)
                        //console.log(success.data);
                        // _this.loadItems();
                        _this.loadCartDetails();

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }
            },
            updateItemQtyButton(sku, qty, index, stepDirection){
                var _this = this,
                    new_qty = qty;

                if ( stepDirection == '+' && qty < 100 ) {
                    new_qty = parseInt(qty) + 1;
                    _this.updateItemQty(sku, new_qty);
                    _this.items[index]['quantity'] = new_qty;
                } else if( stepDirection == '-' && qty > 1 ) {
                    new_qty = parseInt(qty) - 1;
                    _this.updateItemQty(sku, new_qty);
                    _this.items[index]['quantity'] = new_qty;
                }
            },
            individualItemPriceAdjustment:  function(price, qty){
                var totaltemp =  price * qty;
                // return '$' + totaltemp.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                return totaltemp.toFixed(2);
            },
            keyupToPreventInputZero: function(){
                var _this = this;
                //48  = 0
                //96 numpad 0
                //console.log('zero hit');
                // _this.loadCartDetails();
                // this.$forceUpdate();

            },
            enterMethod: function(value){
                value.blur();
            }
        }
    } //end exports default
</script>
