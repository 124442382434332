<template>
    <div class="customer-billing-info-module module checkout-module" id="checkout_step_2">

        <template v-if="$store.state.checkout.step_2_status === 'pending'">
            <div class="module-heading collapsed-heading" id="customer-billing-info-heading">
                <h2 class="h4">2) Billing Info<span class="d-none d-sm-inline">rmation</span></h2>
                <p class="h5">Step 2 of 3</p>
            </div>
        </template>


        <template v-if="$store.state.checkout.step_2_status === 'complete'">

            <div class="module-heading" id="customer-billing-info-heading">
                <h2 class="h4">2) Billing Info<span class="d-none d-sm-inline">rmation</span></h2>
                <p>
                    <a class="vue-anchor text-purple-medium" href="javascript:void(0)" @click="editClick()">Edit</a>
                    <span class="icon-check"></span>
                </p>
            </div>

            <div class="module-completed-properties module-inner-content">
                <div class="row">
                    <div class="col-md-6">
                        <h3 class="h5 text-purple">Billing Address</h3>
                    </div>
                    <div class="col-md-6">
                        <p class="mb-0">{{$store.state.checkout.payment.billing_address.billing_first_name}} {{$store.state.checkout.payment.billing_address.billing_last_name}}</p>
                        <p class="mb-0">{{$store.state.checkout.payment.billing_address.billing_address_1}}</p>
                        <p class="mb-0">{{$store.state.checkout.payment.billing_address.billing_city}}, {{$store.state.checkout.payment.billing_address.billing_state}} {{$store.state.checkout.payment.billing_address.billing_postcode}}</p>
                    </div>
                </div>
                <hr class="my-2">
                <div class="row">
                    <div class="col-md-6">
                        <h3 class="h5 text-purple">Payment Method</h3>
                    </div>
                    <div class="col-md-6">
                        <template v-if="$store.state.checkout.use_saved_card">
                            <p class="mb-0" style="text-transform: capitalize">Brand: {{ $store.state.checkout.payment_method.card.brand }}</p>
                            <p class="mb-0">Last 4 Digits: {{$store.state.checkout.payment_method.card.last4}}</p>
                            <p class="mb-0">Exp: {{$store.state.checkout.payment_method.card.exp_month}} / {{$store.state.checkout.payment_method.card.exp_year}}</p>
                        </template>
                        <template v-else>
                            <p class="mb-0" style="text-transform: capitalize">Brand: {{ $store.state.checkout.payment_method.card.brand }}</p>
                            <p class="mb-0">Last 4 Digits: {{$store.state.checkout.payment_method.card.last4}}</p>
                            <p class="mb-0">Exp: {{$store.state.checkout.payment_method.card.exp_month}} / {{$store.state.checkout.payment_method.card.exp_year}}</p>
                        </template>
                    </div>
                </div>
            </div>

        </template>


        <template v-if="$store.state.checkout.step_2_status === 'active'">

            <div class="module-heading" id="customer-billing-info-heading">
                <h2 class="h4">2) Billing Info<span class="d-none d-sm-inline">rmation</span></h2>
                <p class="h5">Step 2 of 3</p>
            </div>

            <transition name="slide">
                <div class="module-inner-content">
                    <template v-if="errors.length > 0">
                        <p class="help-block">There are some errors / required fields you may have missed below.</p>
                    </template>
                    <form v-on:submit.prevent="submit">

                        <h3 class="h5 text-purple">Billing Information</h3>

                        <hr>
                        <div class="billing-address-type form-row align-items-start">
                            <div class="form-group col-lg-6 my-1" v-if="$store.state.checkout.authcheck && $store.state.checkout.payment_method.id != null">
                                <div class="custom-control custom-checkbox custom-control-highlight">
                                    <input class="custom-control-input" type="radio" name="billing_address" id="saved_payment" value="true" v-model="saved_payment" v-on:change="savedPayment">
                                    <label for="saved_payment" class="custom-control-label">Use Saved Payment</label>
                                </div>
                            </div>
                            <div class="form-group col-lg-6 my-1">
                                <div class="custom-control custom-checkbox custom-control-highlight">
                                    <input class="custom-control-input" type="radio" name="billing_address" id="same_as_shipping" value="true" v-model="same_as_shipping" v-on:change="sameAsShipping">
                                    <label for="same_as_shipping" class="custom-control-label">Same As Shipping Address</label>
                                </div>
                            </div>
                            <div class="form-group col-lg-6 my-1">
                                <div class="custom-control custom-checkbox custom-control-highlight">
                                    <input class="custom-control-input" type="radio" name="billing_address" id="enter_new_address" value="true" v-model="enter_new_address" v-on:change="enterNewAddress">
                                    <label for="enter_new_address" class="custom-control-label">Different From Shipping Address</label>
                                </div>
                            </div>
                        </div>

                            <!-- <div class="form-check form-check-inline" v-if="$store.state.checkout.authcheck && $store.state.checkout.payment_method.id != null">
                              <input class="form-check-input" type="radio" id="saved_payment" value="true" name="billing_address" v-model="saved_payment" v-on:change="savedPayment">
                              <label class="form-check-label create-account" for="create_account">Use Saved Payment</label>
                            </div> -->
                            <!-- <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="same_as_shipping" value="true" name="billing_address" v-model="same_as_shipping" v-on:change="sameAsShipping">
                              <label class="form-check-label create-account" for="create_account">Same As Shipping Address</label>
                            </div> -->
                            <!-- <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="enter_new_address" value="true" name="billing_address" v-model="enter_new_address"  v-on:change="enterNewAddress">
                              <label class="form-check-label create-account" for="create_account">Different From Shipping Address</label>
                            </div> -->
                        <hr>

                        <template v-if="enter_new_address">

                            <h3 class="h5 text-purple mt-2">Billing Address</h3>
                            <div class="form-row">
                                <div class="form-group col-md-6" :class="errors.billing_first_name ? ' has-error' : ''">
                                    <label for="billing_first_name">First Name</label>
                                    <input type="text" class="form-control" id="billing_first_name" placeholder="Enter First Name" v-model="$store.state.checkout.payment.billing_address.billing_first_name">
                                    <form-error v-if="errors.billing_first_name" :errors="errors">
                                        {{ errors.billing_first_name[0] }}
                                    </form-error>
                                </div>
                                <div class="form-group col-md-6" :class="errors.billing_last_name ? ' has-error' : ''">
                                    <label for="billing_last_name">Last Name</label>
                                    <input type="text" class="form-control" id="billing_last_name" placeholder="Enter Last Name" v-model="$store.state.checkout.payment.billing_address.billing_last_name">
                                    <form-error v-if="errors.billing_last_name" :errors="errors">
                                        {{ errors.billing_last_name[0] }}
                                    </form-error>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="billing_company">Company (Optional)</label>
                                    <input type="text" class="form-control" id="billing_company" placeholder="Enter Company Name (Optional)" v-model="$store.state.checkout.payment.billing_address.billing_company">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-12" :class="errors.billing_address_1 ? ' has-error' : ''">
                                    <label for="billing_address_1">Address</label>
                                    <input type="text" class="form-control" id="billing_address_1" placeholder="Enter Address" v-model="$store.state.checkout.payment.billing_address.billing_address_1">
                                    <form-error v-if="errors.billing_address_1" :errors="errors">
                                        {{ errors.billing_address_1[0] }}
                                    </form-error>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="billing_address_2">Apartment, Suite, Building (Optional)</label>
                                    <input type="text" class="form-control" id="billing_address_2" placeholder="Enter Address Line 2 (Optional)" v-model="$store.state.checkout.payment.billing_address.billing_address_2">
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-6" :class="errors.billing_city ? ' has-error' : ''">
                                    <label for="billing_city">City</label>
                                    <input type="text" class="form-control" id="billing_city" placeholder="Enter City" v-model="$store.state.checkout.payment.billing_address.billing_city">
                                    <form-error v-if="errors.billing_city" :errors="errors">
                                        {{ errors.billing_city[0] }}
                                    </form-error>
                                </div>
                                <div class="form-group col-md-6" :class="errors.billing_state ? ' has-error' : ''">
                                    <label for="billing_state">State</label>
                                   <!--  <input type="text" class="form-control" id="billing_state" placeholder="Enter State" v-model="$store.state.checkout.payment.billing_address.billing_state"> -->
                                    <select  class="form-control" id="billing_state" v-model="$store.state.checkout.payment.billing_address.billing_state">
                                            <option value="" disabled="" selected="">Enter State</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="DC">District Of Columbia</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NJ">New Jersey</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NY">New York</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode Island</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WY">Wyoming</option>
                                        </select>

                                    <form-error v-if="errors.billing_state" :errors="errors">
                                        {{ errors.billing_state[0] }}
                                    </form-error>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6" :class="errors.billing_postcode ? ' has-error' : ''">
                                    <label for="billing_postcode">Zip Code</label>
                                    <input type="text" class="form-control" id="billing_postcode" placeholder="Enter Zip Code" v-model="$store.state.checkout.payment.billing_address.billing_postcode">
                                    <form-error v-if="errors.billing_postcode" :errors="errors">
                                        {{ errors.billing_postcode[0] }}
                                    </form-error>
                                </div>
                                <div class="form-group col-md-6" :class="errors.billing_country ? ' has-error' : ''">
                                    <label for="billing_country">Country</label>
                                    <select type="text" class="form-control" id="billing_country" v-model="$store.state.checkout.payment.billing_address.billing_country" >
                                        <option value="US" selected>United States</option>
                                    </select>
                                    <form-error v-if="errors.billing_country" :errors="errors">
                                        {{ errors.billing_country[0] }}
                                    </form-error>
                                </div>
                            </div>
                        </template>

                        <template v-if="same_as_shipping">
                            <div class="row">
                                <div class="col-6">
                                    <div class="card-same-as-shipping mt-2 mb-1">
                                        <p class="mb-0">{{$store.state.checkout.payment.billing_address.billing_first_name}} {{$store.state.checkout.payment.billing_address.billing_last_name}}</p>
                                        <p class="mb-0">{{$store.state.checkout.payment.billing_address.billing_address_1}}</p>
                                        <p class="mb-0">{{$store.state.checkout.payment.billing_address.billing_city}}, {{$store.state.checkout.payment.billing_address.billing_state}} {{$store.state.checkout.payment.billing_address.billing_postcode}}</p>
                                        <p class="mb-0">{{$store.state.checkout.payment.billing_address.billing_country}}</p>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-if="$store.state.checkout.use_saved_card">
                            <p>Payment On File: <br>
                                Brand: {{ $store.state.checkout.payment_method.card.brand }} <br>
                                Last 4 Digits: {{$store.state.checkout.payment_method.card.last4}}
                                Exp: {{$store.state.checkout.payment_method.card.exp_month}} {{$store.state.checkout.payment_method.card.exp_year}}
                            </p>
                        </template>
                        <template v-else>
                            <h3 class="h5 text-purple mt-2">Payment Method</h3>
                            <div class="payment_method_icons mb-2">
                                <ul class="list-inline">
                                    <li class="list-inline-item">
                                        <img src="storage/images/cart/visa.svg" alt="Visa">
                                    </li>
                                    <li class="list-inline-item">
                                        <img src="storage/images/cart/mastercard.svg" alt="Master Card">
                                    </li>
                                    <li class="list-inline-item">
                                        <img src="storage/images/cart/american-express.svg" alt="American Express">
                                    </li>
                                    <li class="list-inline-item">
                                        <img src="storage/images/cart/discover.svg" alt="Discover">
                                    </li>
                                    <li class="list-inline-item">
                                        <img src="storage/images/cart/dinerclubs.svg" alt="Diners Club">
                                    </li>
                                </ul>

                                <!-- <span class="payment_icon">VISA</span>
                                <span class="payment_icon">Master Card</span>
                                <span class="payment_icon">American Express</span>
                                <span class="payment_icon">Discover</span> -->

                                <!-- <span class="payment_icon pay-with-paypal">Pay With Paypal</span> -->
                            </div>
                            <div v-if="cardError != null" class="cardErrors">
                                {{cardError}}
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-12" :class="errors.credit_card ? ' has-error' : ''">
                                    <label for="credit_card">Card Number</label> <!-- {{cardBrand}} {{cardBrandClass }} -->
                                    <!-- <input type="text" class="form-control" id="credit_card" placeholder="Enter credit Card" v-model="$store.state.checkout.payment.credit_card" v-cardformat:formatCardNumber> -->
                                    <input type="text" class="form-control" id="credit_card" placeholder="Enter Credit Card" v-model="$store.state.checkout.payment.credit_card" pattern="[a-zA-Z0-9\s]+">
                                    <form-error v-if="errors.credit_card" :errors="errors">
                                        {{ errors.credit_card[0] }}
                                    </form-error>
                                    <!-- {{cc_number}} -->
                                </div>
                            </div>
                             <div class="form-row">
                                <div class="form-group col-md-12" :class="errors.name_on_card ? ' has-error' : ''">
                                    <label for="name_on_card">Name On Card</label>
                                    <input type="text" class="form-control" id="name_on_card" placeholder="Enter Name On Card" v-model="$store.state.checkout.payment.name_on_card">
                                    <form-error v-if="errors.name_on_card" :errors="errors">
                                        {{ errors.name_on_card[0] }}
                                    </form-error>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-4" :class="errors.exp_month ? ' has-error' : ''">
                                    <label for="exp_month">Expiration Month</label>
                                    <!-- <input type="text" class="form-control" id="exp_date" placeholder="Enter Expiration Date" v-model="$store.state.checkout.payment.exp_date" v-cardformat:formatCardExpiry> -->
                                    <!-- <input type="text" class="form-control" id="exp_month" placeholder="MM" v-model="$store.state.checkout.payment.exp_month" pattern="[0-9.]+" maxlength="2" size="2"> -->
                                    <select class="form-control" id="exp_month"  v-model="$store.state.checkout.payment.exp_month">
                                          <option value="01" selected>01 January</option>
                                          <option value="02">02 February</option>
                                          <option value="03">03 March</option>
                                          <option value="04">04 April</option>
                                          <option value="05">05 May</option>
                                          <option value="06">06 June</option>
                                          <option value="07">07 July</option>
                                          <option value="08">08 August</option>
                                          <option value="09">09 September</option>
                                          <option value="10">10 October</option>
                                          <option value="11">11 November</option>
                                          <option value="12">12 December</option>
                                    </select>
                                    <form-error v-if="errors.exp_date" :errors="errors">
                                        {{ errors.exp_month[0] }}
                                    </form-error>
                                </div>
                                <div class="form-group col-md-4" :class="errors.exp_year ? ' has-error' : ''">
                                    <label for="exp_year">Expiration Year (YY)</label>
                                    <!-- <input type="text" class="form-control" id="exp_date" placeholder="Enter Expiration Date" v-model="$store.state.checkout.payment.exp_date" v-cardformat:formatCardExpiry> -->
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                          <div class="input-group-text">20</div>
                                        </div>
                                        <input type="text" class="form-control" id="exp_year" placeholder="YY" v-model="$store.state.checkout.payment.exp_year" pattern="[0-9.]+" maxlength="2" size="2">
                                    </div>
                                    <form-error v-if="errors.exp_year" :errors="errors">
                                        {{ errors.exp_year[0] }}
                                    </form-error>
                                </div>

                                <div class="form-group col-md-4" :class="errors.ccv ? ' has-error' : ''">
                                    <label for="ccv">CVV</label>
                                    <input type="text" class="form-control" id="ccv" placeholder="Enter CCV Number" v-model="$store.state.checkout.payment.ccv">
                                    <!-- <input type="text" class="form-control" id="ccv" placeholder="Enter CCV Number" v-model="$store.state.checkout.payment.ccv"  v-cardformat:formatCardCVC> -->
                                    <form-error v-if="errors.ccv" :errors="errors">
                                        {{ errors.ccv[0] }}
                                    </form-error>
                                </div>
                            </div>

                            <!-- <stripe ref='stripeElement'></stripe> -->
                            <!-- <div ref="card"></div> -->
                            <!-- <div id="card-element"></div> -->

                            <div class="form-row" v-if="$store.state.checkout.user.create_account || ($store.state.checkout.authcheck == true)">
                                <div class="form-group col-lg-6 mt-1 mb-2">
                                    <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" id="save_payment_info" value="save_payment_info" v-model="$store.state.checkout.payment.save_payment_info">
                                        <label for="save_payment_info" class="custom-control-label">
                                            <span class="d-block">Save My Payment Information For Future Orders</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="row mt-1">
                            <div class="col-12">
                                <button type="submit" class="btn btn-primary mb-0">Continue</button>
                            </div>
                        </div>

                    </form>
                </div>
            </transition>
        </template>


    </div>
</template>

<script>

    // import Stripe from './modules/StripeCard.vue';
    // let stripe = Stripe(window.stripe_key),
    // elements = stripe.elements(),
    // card = undefined;

    // // const cardElement = elements.create('card', {style: style});
    // // cardElement.mount('#card-element');

    // let style = {
    //     base: {
    //         border: '1px solid #D8D8D8',
    //         borderRadius: '4px',
    //         color: "#000",
    //     },
    //     invalid: {
    //         // All of the error styles go inside of here.
    //     }
    // };
    import VueCardFormat from 'vue-credit-card-validation'; //https://github.com/wuori/vue-credit-card-validation

    export default {
        // beforeMount(){
        //     card = elements.create('card', style);
        //     card.mount(this.$refs.card);
        // },
        mounted() {
            this.$store.state.checkout.payment.billing_address.billing_country = 'US';
            if (!this.$store.state.checkout.payment.exp_month) {
                this.$store.state.checkout.payment.exp_month = '01';
            }
        },
        data: function () {
            return {
                same_as_shipping:true,
                enter_new_address:false,
                use_saved_card:false,
                errors:[],
                cardError:null,
                cardBrand:null,
                cc_number:null,
                saved_payment:null,
            }
        },
        components: {
             // Stripe,
            VueCardFormat
        },
        computed: {
            cardBrandClass(){
                return this.getBrandClass(this.cardBrand);
            }
        },
        methods:{
            savedPayment(){
                this.$store.state.checkout.use_saved_card =  !this.$store.state.checkout.use_saved_card;
                this.same_as_shipping = false;
                this.enter_new_address = false;
            },
            getBrandClass: function (brand) {
                let icon = '';
                brand = brand || 'unknown';
                let cardBrandToClass = {
                    'visa': 'fab fa-cc-visa',
                    'mastercard': 'fab fa-cc-mastercard',
                    'amex': 'fab fa-cc-amex',
                    'discover': 'fab fa-cc-discover',
                    'diners': 'fab fa-cc-diners-club',
                    'jcb': 'fab fa-cc-jcb',
                    'unknown': 'fa fa-credit-card',
              };
              if (cardBrandToClass[brand]) {
                icon = cardBrandToClass[brand];
              };

              return icon;
            },
            editClick(){
                //console.log('clicked')
                this.$store.state.checkout.active_step = 2;
                // this.$store.state.checkout.step_1_status === 'complete';
                this.$store.state.checkout.step_2_status = 'active';
                this.$store.state.checkout.step_3_status = 'pending';
                this.$store.state.checkout.disablecoupons=false;

            },
            sameAsShipping(){
                this.$store.state.checkout.payment.billing_address.billing_first_name = this.$store.state.checkout.user.shipping_address.shipping_first_name;
                this.$store.state.checkout.payment.billing_address.billing_last_name = this.$store.state.checkout.user.shipping_address.shipping_last_name;
                this.$store.state.checkout.payment.billing_address.billing_company = this.$store.state.checkout.user.shipping_address.shipping_company;
                this.$store.state.checkout.payment.billing_address.billing_address_1 = this.$store.state.checkout.user.shipping_address.shipping_address_1;
                this.$store.state.checkout.payment.billing_address.billing_address_2 = this.$store.state.checkout.user.shipping_address.shipping_address_2;
                this.$store.state.checkout.payment.billing_address.billing_city = this.$store.state.checkout.user.shipping_address.shipping_city;
                this.$store.state.checkout.payment.billing_address.billing_state = this.$store.state.checkout.user.shipping_address.shipping_state;
                this.$store.state.checkout.payment.billing_address.billing_postcode = this.$store.state.checkout.user.shipping_address.shipping_postcode;
                this.$store.state.checkout.payment.billing_address.billing_country = this.$store.state.checkout.user.shipping_address.shipping_country;

                this.$store.state.checkout.use_saved_card = false;
                this.same_as_shipping = true;
                this.enter_new_address = false;
            },
            enterNewAddress(){
                this.$store.state.checkout.payment.billing_address.billing_first_name = null;
                this.$store.state.checkout.payment.billing_address.billing_last_name = null;
                this.$store.state.checkout.payment.billing_address.billing_company = null;
                this.$store.state.checkout.payment.billing_address.billing_address_1 = null;
                this.$store.state.checkout.payment.billing_address.billing_address_2 = null;
                this.$store.state.checkout.payment.billing_address.billing_city = null;
                this.$store.state.checkout.payment.billing_address.billing_state = null;
                this.$store.state.checkout.payment.billing_address.billing_postcode = null;
                this.$store.state.checkout.payment.billing_address.billing_country = 'US';

                this.$store.state.checkout.use_saved_card = false;
                this.same_as_shipping = false;
                this.enter_new_address = true;
            },
            submit(){
                //console.log('submitted');
                this.cardError = null;
                // var somevar = this.$refs.stripeElement.process();
                // console.log(somevar);
                // return;
                //make axios call to update session and validate this data
                this.$store.commit('setLoading' , true);
                axios.post('/checkout/customer-billing', //this is the storeapi .php route file on the marketing site
                {
                    name_on_card:this.$store.state.checkout.payment.name_on_card,
                    credit_card:this.$store.state.checkout.payment.credit_card,
                    // exp_date:this.$store.state.checkout.payment.exp_date,
                    exp_month:this.$store.state.checkout.payment.exp_month,
                    exp_year:this.$store.state.checkout.payment.exp_year,
                    ccv:this.$store.state.checkout.payment.ccv,
                    billing_first_name:this.$store.state.checkout.payment.billing_address.billing_first_name,
                    billing_last_name:this.$store.state.checkout.payment.billing_address.billing_last_name,
                    billing_company:this.$store.state.checkout.payment.billing_address.billing_company,
                    billing_address_1:this.$store.state.checkout.payment.billing_address.billing_address_1,
                    billing_address_2:this.$store.state.checkout.payment.billing_address.billing_address_2,
                    billing_city:this.$store.state.checkout.payment.billing_address.billing_city,
                    billing_state:this.$store.state.checkout.payment.billing_address.billing_state,
                    billing_postcode:this.$store.state.checkout.payment.billing_address.billing_postcode,
                    billing_country:this.$store.state.checkout.payment.billing_address.billing_country,
                    save_payment_info:this.$store.state.checkout.payment.save_payment_info,
                    use_saved_card:this.$store.state.checkout.use_saved_card,
                    payment_intent_id:this.$store.state.checkout.payment_intent_id,
                    payment_method_id:this.$store.state.checkout.payment_method.id,
                },
                {
                    headers: {
                        //'Content-Type': 'multipart/form-data'
                    }
                })
                .then(success => {
                    //console.log(success.data);

                    if (success.data.success)   {

                        this.$store.state.checkout.step_2_status = 'complete';
                        this.$store.state.checkout.step_3_status = 'active';
                        this.$store.state.checkout.active_step = 3;

                        if (success.data.message == 'saved-card') {//using card on file
                            //console.log('using saved card');
                            this.$store.state.checkout.payment_method.customer_id = success.data.data.payment_method.customer_id;
                            this.$store.state.checkout.payment_intent_id = success.data.data.payment_intent_id;
                        }else{
                            //save the payment metthods and such
                            this.$store.state.checkout.payment_method = success.data.data.payment_method;
                            this.$store.state.checkout.payment_intent_id = success.data.data.payment_intent_id;
                        }

                        //GTM
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                          event: 'eec.checkout',
                          ecommerce: {
                            checkout: {
                              actionField: {
                                step: 2 + ' Billing',
                                option: success.data.data.payment_method.card.brand
                              }
                            }
                          }
                        });
                        //adding coupons after payment intent will cause stripe not to see them
                        this.$store.state.checkout.disablecoupons=true;

                        this.$nextTick(function () {
                            //console.log('next tick jump checkout_step_3')
                            this.$root.jump('checkout_step_3');
                        })

                    }else if(success.data.message == 'error'){

                      this.errors = success.data.data;
                      // this.$root.jump('customer-billing-info-heading')

                    }else if(success.data.message == 'stripe-error'){
                        //stripe error
                        switch(success.data.data) {

                          case "Your card's expiration year is invalid.":
                            this.errors = {
                                'exp_year': {
                                    0:success.data.data
                                }
                            }
                            break;
                          case "Your card's expiration month is invalid.":
                            this.errors = {
                                'exp_month': {
                                    0:success.data.data
                                }
                            }
                            break;
                          case "The card number is not a valid credit card number.":
                            this.errors = {
                                'credit_card': {
                                    0:success.data.data
                                }
                            }
                            break;

                          case "Your card number is incorrect.":
                            this.errors = {
                                'credit_card': {
                                    0:success.data.data
                                }
                            }
                            break;

                        case "The card number is longer than the maximum supported length of 16.":
                            this.errors = {
                                'credit_card': {
                                    0:success.data.data
                                }
                            }
                            break;

                          case "Your card's security code is invalid.":
                            this.errors = {
                                'ccv': {
                                    0:success.data.data
                                }
                            }
                            break;

                          default:

                            this.errors = {
                                'credit_card': {
                                    0:success.data.data
                                }
                            }

                        }




                        // this.cardError = success.data.data;
                    }//end if stripe error

                    this.$store.commit('setLoading' , false);
                })
                .catch(function (error) {
                    console.log(error);
                });

            }
        }
    }
</script>
