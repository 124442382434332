<template>
    <div class="add-to-cart">

            <div class="d-flex align-items-center mb-2">

                 <div class="qty-field">
                    <div v-if="show_qty" class="input-group">
                        <label for="quantity">Qty:</label>
                        <button class="input-group-btn d-none d-lg-block" type="button" data-type="minus" data-field="quantity" @click="updateQty('-')">
                            <span class="icon-minus"></span>
                        </button>
                        <input class="input-number mb-0" type="text" name="quantity" inputmode="numeric" min="1" max="100" step="1" aria-label="quantity" v-model="qty">
                        <button class="input-group-btn d-none d-lg-block" type="button" data-type="plus" data-field="quantity" @click="updateQty('+')">
                            <span class="icon-plus"></span>
                        </button>
                    </div>
                </div>

                <div v-if="price" class="price ml-2">${{price}}</div>

            </div>


        <div v-if="false">
            <a v-once class="button button-yellow rounded-pill text-uppercase view-cart">In Cart</a>
            <br>
            <a class="vue-anchor" :href="this.$parent.checkout_url">Proceed to Checkout</a>
            <span @click="remove()"><small>(XRemove)</small></span>
        </div>

        <button class="btn btn-primary btn-add-to-cart d-inline-block px-5 py-1 vue-anchor" v-else @click="add()">
            <span  v-if="this.$parent.$mq === 'sm' || this.$parent.$mq === 'md' || this.$parent.$mq === 'lg' || this.$parent.$mq === 'xl' || this.$parent.$mq === 'xxl'">Add To Cart</span>
            <span v-else>Buy</span>
        </button>

    </div>
</template>

<script>
    export default {
        props:{
            id:{
                type: [Number, String],
                required: true,

            },
            product_price:{
                type: [Number, String],
                required: false,
                default: null
            },
            product_in_cart:{
                type: Boolean,
                required: false,
                default: null
            },
            show_qty_field:{
                type: [Boolean, String],
                required: false,
                default: null
            }
        },
        mounted() {
            console.log(document.title.replace(' - ClueJay' , ''));
        },
        data: function () {
            return {
                in_cart: this.product_in_cart,
                show_qty: this.show_qty_field,
                qty: 1,
                price:this.product_price
            }
        },
        methods:{
            add(){
                axios.post('/cart/add', {
                    id: this.id,
                    qty: this.qty
                })
                .then(success => {
                    this.$parent.getCount();
                    this.$parent.$bvToast.toast(`Item Added To Cart `, {
                        title: 'Success',
                        variant:'success',
                        autoHideDelay: 5000,
                        appendToast: true
                    });


                    //gtm push
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: 'eec.add',
                      ecommerce: {
                        add: {
                            actionField: {
                                list: document.title.replace('- ClueJay' , '')
                            },
                            product:{
                                id: this.id,
                                name: success.data.data.name,
                                category: success.data.data.categories[0].slug,
                                brand: 'ClueJay',
                                quantity: this.qty,
                            }
                        }
                      }
                    });

                   window.location.href = '/cart';

                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            remove(){
                axios.post('/cart/remove', {
                    id:this.id
                })
                .then(success => {
                    this.$parent.getCount();
                    this.$parent.$bvToast.toast(`Item Removed From Cart `, {
                        title: 'Success',
                        variant:'success',
                        autoHideDelay: 5000,
                        appendToast: true
                    })

                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            updateQty(stepDirection){
                if ( stepDirection == '+' && this.qty < 100 )
                    this.qty = parseInt(this.qty) + 1;
                else if( stepDirection == '-' && this.qty > 1 )
                    this.qty = parseInt(this.qty) - 1;
            }
        }
    }
</script>
