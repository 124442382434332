<template>
    <div class="order-summary container-fluid">

        <div class="d-flex justify-content-end">
            <h2 class="h4 mb-0 mr-auto">
                <span class="d-none d-lg-block">Order Summary</span>
                <button class="order-summary-toggle text-purple collapsed d-block d-lg-none" data-toggle="collapse" data-target="#archiveNavigation" aria-expanded="false" aria-controls="archiveNavigation">
                    <span>Order Summary</span>
                    <span class="icon-chevron-down"></span>
                </button>
            </h2>
            <a class="vue-anchor text-purple-medium d-none d-lg-block" :href="this.$root.cart_url">Edit</a>

            <span class="h3 text-blue mb-0 d-lg-none">{{ formatCurrency($store.state.checkout.total) }}</span>
        </div>

        <div class="order-summary-content collapse show d-lg-block mt-2" id="archiveNavigation">

            <hr class="my-2 d-lg-none">

            <div class="row align-items-center mb-2" v-for="item in items">
                <div class="col-12">
                    <div class="d-flex align-items-center">
                        <div class="item-thumbnail mr-1">
                            <img :src="item.attributes.images[0].src" alt="item.name">
                            <div class="counter"><span>{{item.quantity}}</span></div>
                        </div>
                        <div class="item-name mr-1" v-html="nameHelper(item.name)"></div>
                        <div class="item-price text-blue ml-auto">{{ formatCurrency(item.price * item.quantity) }}</div>
                    </div>
                </div>
            </div>

            <hr class="my-2">

            <template v-if="!$store.state.checkout.disablecoupons">
                <div class="row">
                    <div class="col">
                        <coupon v-on:foo="foo()"></coupon>
                    </div>
                </div>
                <hr class="my-2">
            </template>
<!--             <template v-else>
                <div class="coupon-wrapper">
                    <h3 class="h4 text-small mb-1">Have a Gift Card or Coupon Code?</h3>
                    <p class="text-small">Please click edit billing to add coupons.</p>
                </div>
            </template> -->
            <div class="order-summary-subtotal row text-small mb-1">
                <div class="col">
                    <span class="subtotal text-purple">Subtotal</span>
                </div>
                <div class="col text-right">
                    {{ formatCurrency($store.state.checkout.subtotal) }}
                </div>
            </div>
            <!-- <template v-if="$root.isNotEmpty($store.state.checkout.coupons)"> -->
            <template v-if="$store.state.checkout.coupons.length >0">
                <div class="order-summary-subtotal row text-small mb-1">
                    <div class="col">
                        <span class="subtotal text-purple">Coupons</span>
                    </div>
                    <div class="col text-right">

                        <span style="display:block" v-for="coupon in $store.state.checkout.coupons">
                            {{coupon.value}}
                        </span>

                        <span style="display:block" v-for="coupon in $store.state.checkout.coupons">
                            {{coupon.code}} <small @click="removeCoupon(coupon.code)">[X]</small>
                        </span>

                    </div>
                </div>
            </template>

            <div class="order-summary-subtotal row text-small mb-1">
                <div class="col">
                    <span class="subtotal text-purple">Shipping</span>
                </div>
                <div class="col text-right">
                    <span class="d-block">{{$store.state.checkout.shipping.shipping_title}}</span>
                    <template v-if="$store.state.checkout.shipping.shipping_cost == null">
                         <span class="d-block" >Please Select Shipping</span>
                    </template>
                    <template v-else>
                       <span class="d-block" v-if="$store.state.checkout.shipping.shipping_cost == 0">Free</span>
                        <span class="d-block" v-else>{{ formatCurrency($store.state.checkout.shipping.shipping_cost) }}</span>
                    </template>
                </div>
            </div>

            <div class="order-summary-subtotal row text-small mb-1">
                <div class="col">
                    <span class="subtotal text-purple">Tax</span>
                </div>
                <div class="col text-right">
                    <template v-if="$store.state.checkout.tax.amount_to_collect || $store.state.checkout.tax.amount_to_collect == 0">
                        {{ formatCurrency($store.state.checkout.tax.amount_to_collect) }}
                    </template>
                    <template v-else>
                        Based on shipping address
                    </template>
                </div>
            </div>

            <hr class="my-2">

            <div class="row justify-content-end align-items-center">
                <div class="col">
                    <img src="/images/sectigo_trust_seal_lg.png" alt="" />
                </div>
                <div class="col text-right">
                    <div class="d-flex justify-content-end align-items-center">
                        <span class="d-inline-block text-small mr-1">Total</span>
                        <span class="h3 text-blue mb-0">{{ formatCurrency($store.state.checkout.total) }}</span>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import coupon from './CouponComponent.vue';

    export default {
        props:['items'],
        mounted() {
            //console.log(this.items)
            this.$root.getCoupons();
        },
        components: {
            coupon
        },
        methods:{
            formatCurrency(value) {
                return this.$filters.formatCurrency(value);
            },
            removeCoupon(code){
                //console.log('remove ' + code);

                if(confirm("Do you really want to remove " + code + "?")){


                    axios.post('/checkout/coupons/remove',
                    {
                        code:code,
                    }
                    )
                    .then(success => {
                        //console.log(success.data);
                        if (success.data.success) {
                            //console.log('success')
                            this.$root.updateCartTotals();
                            this.$root.getCoupons();
                        }else{
                          // this.errors = success.data.data;
                          // this.$root.jump('jumpsomeplace')
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }
            },
            nameHelper(name){

                if (name.indexOf('-') > -1)
                {
                    var x = name.split('-');
                    return  '<p class="item-name h4 text-small mb-0">'+ x[0] + '</p>' +  '<p class="item-subname text-small mb-0">'+ x[1] + '</p>';
                }
                return name;
            },
            foo(){
                console.log('foo if needed')
            }
        }
    }
</script>
