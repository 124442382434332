<template>
    <div class="checkout-wrapper" v-if="!loading">
        <div class="row no-gutters d-flex align-items-stretch bd-highlight">
            <div class="col-lg-4 order-lg-2" style="background: white;">
                <order-summary :items="$store.state.checkout.items"></order-summary>
            </div>
            <div class="col-lg-8">
                <div class="checkout-steps">
                    <div class="checkout-errors" v-if="$store.state.checkout.errors">
                        {{$store.state.checkout.errors}}
                    </div>

                    <p class="sign-in-prompt h4 text-purple text-small" v-if="!authcheck">Already Have Account? <a href="/login">Sign In</a></p>

                    <customerinfo></customerinfo>

                    <customer-billing-info></customer-billing-info>

                    <order-review></order-review>

                    <div class="checkout-errors" v-if="$store.state.checkout.errors">
                        {{$store.state.checkout.errors}}
                    </div>

                </div>
            </div>
       </div>
    </div>
</template>

<script>
    import customerInformation from './CustomerInformationComponent.vue';
    import customerBillingInformation from './CustomerBillingInformationComponent.vue';
    import orderReview from './OrderReviewComponent.vue';
    import orderSummary from './OrderSummaryComponent.vue';


    export default {
        props: [
            'authcheck', //auth()
            'items', //items
            'checkout', //checkout session object
            'subtotal',
            'total',
            'count'
        ],
        mounted() {
            this.setObjects();

            // console.log(this.checkout)
            // console.log(this.$parent.cart.count)
            // this.$parent.cart.count = 1;
            // console.log(this.cartObject)
            // console.log(this.$parent.cart.count)
        },
        data: function () {
            return {
                loading:true
            }
        },
        components: {
            customerinfo:customerInformation,
            'customer-billing-info':customerBillingInformation,
            orderReview,
            orderSummary,
        },
        methods: {
            setObjects(){
                // console.log(this.total + ' in checkoutcomponent')
                //quickly trying to solve the over 1000 dollars comma issue here, shipping and summary
                // var total = this.total;

                // if(this.total.includes(',')){
                //     console.log('total has commas');
                //     var total = total.replace(/,/g, '');
                // }
                // console.log(total)

                this.$store.commit('setLoading' , true);
                this.$store.commit('setTotal', this.total)
                this.$store.commit('setInitTotal', this.total)
                this.$store.commit('setSubTotal', this.subtotal)
                this.$store.commit('setItems', JSON.parse(this.items));
                this.$store.state.checkout.count = this.count;
                if (this.authcheck) {
                    this.getUser();
                    this.getPaymentMethods();
                }else{
                    if(this.checkout != 'null'){
                        //console.log('no auth, get session')
                        this.getSession();
                    }
                }
                this.loading = false;
                this.$store.commit('setLoading' , false);
            },
            getUser(){
                // console.log('get user')
                this.$store.commit('setLoading' , true);
                axios.get('/api/v1/checkout-user')
                .then(success => {
                    //console.log(success.data)
                    this.$store.commit('setUser', success.data.data)
                    this.$store.state.checkout.authcheck = true;

                    //set session user over user
                    if(this.checkout != 'null'){
                        //console.log('get session after getting user')
                        this.getSession();
                    }
                    this.$store.commit('setLoading' , false);

                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            getPaymentMethods(){
                this.$store.commit('setLoading' , true);
                //console.log('get payment methods');
                axios.get('/api/v1/checkout-user/payment-methods')
                .then(success => {

                    //console.log(success.data)

                    if (success.data.success) {
                        this.$store.state.checkout.payment_method = success.data.data;
                    }
                    this.$store.commit('setLoading' , false);

                })
                .catch(function (error) {
                    console.log(error);
                });


            },
            getSession(){
                //console.log('getting session info')
                //this.$root.consolelog('Non Auth User-- Grabbing Existing Session to populate values')
                // console.log('get session')
                var session = JSON.parse(this.checkout);
                //this.$root.consolelog(session)
                //console.log(session);

                var user = session.user;
                this.$store.commit('setUser', user)

                if (this.$root.isNotEmpty(session.payment)) {
                    //console.log('has billing payment info')
                    this.$store.state.checkout.payment = session.payment;
                }
                if (session.payment_intent_id != null) {
                    //console.log('has payment intent')
                    this.$store.state.checkout.payment_intent_id = session.payment_intent_id;
                }
                if (this.$root.isNotEmpty(session.payment_method)) {
                    //console.log('has payment method')
                    if (session.payment_method.id != null) {
                        this.$store.state.checkout.payment_method.id = session.payment_method.id ;
                    }
                }

                this.$store.state.checkout.shipping = session.shipping;
                //console.log(session.tax)
                if (this.$root.isNotEmpty(session.tax) ) {
                    this.$store.state.checkout.tax.amount_to_collect = session.tax.data.amount_to_collect
                }
            }
        }
    }
</script>
