<template>
    <div class="customer-info-module module checkout-module" id="checkout_step_3">

        <template v-if="$store.state.checkout.step_3_status === 'pending'">
            <div class="module-heading collapsed-heading" id="customer-billing-info-heading">
                <h2 class="h4">3) Order Review</h2>
                <p class="h5">Step 3 of 3</p>
            </div>
        </template>

        <template v-if="$store.state.checkout.step_2_status === 'complete' &&  $store.state.checkout.step_1_status === 'complete' && $store.state.checkout.step_3_status === 'active'">

            <div class="module-heading" id="customer-info-heading">
                <h2 class="h4">3) Order Review</h2>
                <p class="h5">Step 3 of 3</p>
            </div>
            <div class="module-inner-content">
                <table class="order-review-table table text-gray">
                  <tbody>
                    <tr>
                      <th class="text-purple" scope="row">Subtotal</th>
                      <td>{{$store.state.checkout.count }} Item(s)</td>
                      <td class="text-right">{{ formatCurrency($store.state.checkout.subtotal) }}</td>
                    </tr>
                    <tr>
                      <th class="text-purple" scope="row">Shipping</th>
                      <td>{{$store.state.checkout.shipping.shipping_title}}</td>
                      <td class="text-right" v-if="$store.state.checkout.shipping.shipping_cost == 0">Free</td>
                      <td class="text-right" v-else>{{ formatCurrency($store.state.checkout.shipping.shipping_cost) }}</td>
                    </tr>
                    <tr>
                      <th class="text-purple" scope="row">Total</th>
                      <td></td>
                      <td class="text-right text-purple">{{ formatCurrency($store.state.checkout.total) }}</td>
                    </tr>
                  </tbody>
                </table>

<!--                 <div class="terms-conditions-agreement custom-control custom-checkbox my-2">
                    <input class="custom-control-input" type="checkbox" id="i_agree" value="true" name="i_agree" v-model="$store.state.checkout.i_agree">
                    <label for="i_agree" class="custom-control-label">
                        <span class="text-purple">I Agree To The <a href="/terms-conditions" target="_blank">Terms Of Service</a></span>
                    </label>
                    <form-error v-if="errors.i_agree" :errors="errors">
                        {{ errors.i_agree[0] }}
                    </form-error>
                </div> -->

                <div class="row mt-2">
                    <div class="col-8">
                        <button type="submit" class="btn btn-primary mb-0" @click="submit" :disabled="$store.state.loading">Confirm Purchase</button>
                    </div>
                </div>
            </div>

        <form style="display: none;" action="/checkout/thank-you" method="POST" id="thankyouForm" ref="thankyouForm" >
            <input type="hidden" name="_token" :value="submitToken">
            <input type="hidden" id="order_id" name="order_id" v-model="order_id"/>
            <input type="hidden" id="user_name" name="user_name" v-model="user_name" />
            <button id="thankYouButton" type="submit"></button>
        </form>

        </template>


    </div>
</template>

<script>
    export default {
        mounted() {

        },
        data: function () {
            return {
                errors:[],
                order_id:null,
                user_name:null,
                successForm:false
            }
        },
        computed:{
            submitToken(){
               return document.head.querySelector('meta[name="csrf-token"]').content;
            }
        },
        methods:{
            formatCurrency(value) {
                return this.$filters.formatCurrency(value);
            },
            submit(){
                this.$root.jump('app');

                this.$store.commit('setLoading' , true);
                axios.post('/checkout',
                {
                    checkout:this.$store.state.checkout
                },
                {
                    headers: {
                        //'Content-Type': 'multipart/form-data'
                    }
                })
                .then(success => {
                    //console.log(success.data)
                    if (success.data.success) {

                        this.order_id = success.data.data.order_id;
                        this.user_name = success.data.data.user_name;
                        if (window.APP_ENV == 'production') {

                            //facebook
                            fbq('track', 'Purchase', {
                                value: success.data.data.order.total,
                                currency: 'USD'
                            });

                        }


                        // //GTM
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                          event: 'eec.purchase',
                          ecommerce: {
                            currencyCode: success.data.data.order.currency,
                            purchase: {
                              actionField: {
                                id: success.data.data.order.id,
                                //affiliation: 'Simo\'s shop', //use this for when we set a cookie from (ask.vet)
                                revenue: success.data.data.order.total,
                                tax: success.data.data.order.total_tax,
                                shipping: success.data.data.order.shipping_total,
                                coupons: success.data.data.order.coupon_lines
                              },
                              products: success.data.data.order.line_items
                            }
                          }
                        });

                        //redirect to thank you page by submitting hidden post
                        this.$nextTick(function () {
                           this.$refs.thankyouForm.submit();
                        })

                        //$('#thankyouForm').submit();
                    // window.location.href = '/checkout/thank-you'

                    }else if(success.data.message == 'stripe-error'){
                        // this.$root.jump('customer-billing-info-heading')
                        this.$store.state.checkout.errors = success.data.data;
                        this.$parent.$bvToast.toast(success.data.data, {
                            title: 'Payment Error',
                            variant:'danger',
                            autoHideDelay: 5000,
                            appendToast: true
                        })


                    }else{
                        this.errors = success.data.data;
                    }
                    this.$store.commit('setLoading' , false);
                 })
                .catch(function (error) {
                    console.log(error);
                });
            }
        }
    }
</script>
