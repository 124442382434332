$('.add_to_cart_button').click(function(e) {
    e.preventDefault()
    // console.log(e)
    var href = $(this).attr('href');
    var item_id = parseInt(href.replace("?add-to-cart=", ""));
    console.log(item_id)


    axios.post('/cart/add', {
            id:item_id
        })
        .then(success => {
            console.log(success.data);
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {
        });



});


