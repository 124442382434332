<template>
    <div>
        <a class="navbar-text btn-cart cart_count-anchor" :href="this.$parent.cart_url">
            <span class="icon-cart"></span>
            <span class="screen-reader-text">Cart</span>
            <div class="counter" v-if="this.$parent.cart.count > 0">
                    <span>{{this.$parent.cart.count}}</span>
            </div>
        </a>
    </div>
</template>

<script>
    export default {
        props:['cart_count'],
        mounted() {
            // console.log('cart count mounted')
            this.$parent.cart.count = this.cart_count
        },
        data: function(){
            return {

            }
        },
        methods:{

        }
    }
</script>
