<template>
    <div class="coupon-wrapper">
        <h3 class="h4 text-small mb-1">Have a Gift Card or Coupon Code?</h3>

        <form class="coupon-form d-flex justify-content-between align-items-center" v-on:submit.prevent="applyCoupon">
            <div class="form-group mb-0" :class="errors.code ? ' has-error' : ''">
                <label class="screen-reader-text" for="coupon">Gift Card or Coupon Code</label>
                <input class="form-control mb-0" type="text" id="coupon" name="code" v-model="code" placeholder="Enter Code">

                <form-error v-if="errors.code" :errors="errors">
                    {{ errors.code[0] }}
                </form-error>

            </div>
            <button type="submit" class="btn btn-secondary btn-wide mb-0">Apply</button>
        </form>

       <!--  <p class="mt-2"><small>*You will not be able to add coupons after entering billing information</small></p> -->
        <p v-if="message">{{message}}</p>

    </div>
</template>

<script>
    export default {
        mounted() {

        },
        data: function () {
            return {
                code: null,
                errors:[],
                message:null
            }
        },
        methods:{
            applyCoupon(){
                if(this.code == null || this.code == ''){
                    return
                }
                this.errors = [];
                this.message = null;

                console.log('apply coupon: ' + this.code)
                axios.post('/checkout/coupons/apply',
                {
                    code:this.code,
                }
                )
                .then(success => {
                    console.log(success.data);
                    if (success.data.success) {
                        //console.log('success')
                        this.message = 'Coupon Applied';
                        // this.$store.commit('setLoading' , true);
                        // this.$store.commit('applyCoupon', success.data.data)
                        // this.$state.store.checkout.coupons.push(success.data.data)
                        //console.log('coupon added to array')
                        this.$root.updateCartTotals();
                        this.$root.getCoupons();


                    }else{
                      this.errors = success.data.data;
                      // this.$root.jump('jumpsomeplace')
                    }
                    // this.$store.commit('setLoading' , false);
                })
                .catch(function (error) {
                    console.log(error);
                });

                // this.$root.cart.count =2;
                this.$emit('foo', 'bar')
                this.code = null;
            }
        },


    }
</script>
